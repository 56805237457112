<template>
  <div>
    <b-card no-body>
      <div class="d-flex justify-content-between align-items-center px-1 py-1" >
        <h5 class="m-0 font-weight-bolder" >{{ $t('Productos') }}</h5>
      </div>
      <b-table
          hover
          responsive
          :items="items"
          :fields="fields"
      >
        <template #cell(name)="data">
          <div class="d-flex align-items-center" >
            <div :style="`background-image: url('${data.item.imagen}');`" class="d-inline-block img-product-list mr-50" >
            </div>
            <p class="d-inline-block m-0" >{{ data.item.name }}</p>
          </div>
        </template>
        <template #cell(actions)="data">
          <b-link
            :to="{ name: 'viewProduct', params: { id: data.item.id} }"
            target="_blank"
          >
            <feather-icon
                icon="EyeIcon"
                size="16"
            />
          </b-link>
        </template>
      </b-table>
    </b-card>
    <div>
      <b-pagination
        v-model="currentPage"
        :total-rows="totalItems"
        :per-page="pageLength"
        first-number
        last-number
        align="right"
        prev-class="prev-item"
        next-class="next-item"
        class="mt-1 mb-0"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
    </div>
  </div>
</template>

<script>
import {
  BFormInput, BCollapse, BCard, BTable, BPagination, BRow, BCol, BBadge, BFormGroup, BLink,
} from 'bootstrap-vue'
import { TimestampToFlatPickr } from '@/libs/helpers'
import { mapActions, mapGetters } from 'vuex'
import { config } from '@/shared/app.config'
import vSelect from 'vue-select'

export default {
  props: {
    clientprop: {
      type: Number,
      default: 0,
    },
  },
  components: {
    BCard,
    BTable,
    BPagination,
    BBadge,
    BRow,
    BCol,
    BCollapse,
    BFormGroup,
    BLink,
    BFormInput,
    vSelect,
  },
  data() {
    return {
      currentPage: 1,
      visibleFilter: false,
      pages: ['10', '15', '25'],
      pageLength: 10,
      statusAssets: config.statusAssetsVariants,
      searchTerm: '',
      status: '',
      prox_revision: '',
      selectProxRevision: [
        {
          label: this.$t('1mes'),
          value: 1,
        },
        {
          label: this.$t('3meses'),
          value: 3,
        },
      ],
      vidaUtil: '',
      selectVidaUtil: [
        {
          label: this.$t('1mes'),
          value: 1,
        },
        {
          label: this.$t('3meses'),
          value: 3,
        },
        {
          label: this.$t('6meses'),
          value: 6,
        },
        {
          label: this.$t('1anyo'),
          value: 12,
        },
      ],
      fields: [
        {
          label: this.$t('NombreProducto'),
          key: 'name',
        },
        {
          label: this.$t('NActivos'),
          key: 'num',
        },
        {
          label: this.$t('ProximaRevision3meses'),
          key: 'num_revision_3_meses',
        },
        {
          label: this.$t('VidaUtil6meses'),
          key: 'num_vida_util_6_meses',
        },
        {
          label: this.$t('Acciones'),
          key: 'actions',
          class: 'text-right',
        },
      ],
    }
  },
  watch: {
    currentPage() {
      this.chargeData()
    },
    pageLength() {
      this.chargeData()
    },
    status() {
      this.currentPage = 1
      this.chargeData()
    },
    prox_revision() {
      this.currentPage = 1
      this.chargeData()
    },
    vidaUtil() {
      this.currentPage = 1
      this.chargeData()
    },
    currentCompany() {
      this.currentPage = 1
      this.chargeData()
    },
  },
  computed: {
    ...mapGetters({
      currentCompany: 'companies/getCurrentCompany',
      currentClient: 'clients/getCurrentClient',
      items: 'products/getItemsDashboard',
      totalItems: 'products/getTotalItemsDashboard',
    }),
  },
  methods: {
    ...mapActions({
      getProductsDashboard: 'products/getListDashboard',
    }),
    chargeData() {
      const company = (this.currentCompany) ? this.currentCompany.id : ''
      if(company != ''){
        this.getProductsDashboard({
          company, page: this.currentPage, per_page: this.pageLength, search: this.searchTerm,
        })
      }

    },
    dateColumn(date) {
      return TimestampToFlatPickr(date)
    },
    handleSearch() {
      this.currentPage = 1
      this.chargeData()
    },
  },
  async created() {
    await this.chargeData()
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-select.scss';
</style>
