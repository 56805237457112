<template>
  <b-row>
    <b-col sm="3">
      <b-card
        class="bg-success-fade height-card"
      >
        <h4 class="mb-1 d-inline-block">
          <strong class="mr-1">{{ $t('epis') }}</strong>
        </h4>
        <b-badge variant="success">
          {{ $t('AptoDisponible') }}
        </b-badge>
        <h2 class="mb-1">
          <strong>{{ this.aptoDisponibleCurrent }}</strong>
        </h2>
      </b-card>
    </b-col>
    <b-col sm="3">
      <b-card
        class="bg-warning-fade height-card"
      >
        <h4 class="mb-1 d-inline-block">
          <strong class="mr-1">{{ $t('epis') }}</strong>
        </h4>
        <b-badge variant="warning">
          {{ $t('AptoUso') }}
        </b-badge>
        <h2 class="mb-1">
          <strong>{{ this.aptoUsoCurrent }}</strong>
        </h2>
      </b-card>
    </b-col>
    <b-col sm="3">
      <b-card
        class="bg-dark-fade height-card"
      >
        <h4 class="mb-1 d-inline-block">
          <strong class="mr-1">{{ $t('epis') }}</strong>
        </h4>
        <b-badge variant="dark">
          {{ $t('NoAptos') }}
        </b-badge>
        <h2 class="mb-1">
          <strong>{{ this.noAptoCurrent }}</strong>
        </h2>
      </b-card>
    </b-col>
    <b-col sm="3">
      <b-card
        class="bg-info-fade height-card"
      >
        <h4 class="mb-1 d-inline-block">
          <strong class="mr-1">{{ $t('epis') }}</strong>
        </h4>
        <b-badge variant="info">
          {{ $t('ConIncidencia') }}
        </b-badge>
        <h2 class="mb-1">
          <strong>{{ this.incidenciaCurrent }}</strong>
        </h2>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BRow, BCol, BBadge,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    BCard,
    BCol,
    BRow,
    BBadge,
  },
  props: {
    categoryId: {},
    clientprop: {},
    searchProp: {},
    locationProp: {},
    albaranProp: {},
    statusProp: {},
    vida_utilProp: {},
    prox_revisionProp: {},
    userProp: {},
    dateIniProp: {},
    dateFinProp: {},
  },
  data() {
    return {
      type: 'line',
    }
  },
  computed: {
    ...mapGetters({
      currentClient: 'clients/getCurrentClient',
      dashboardEpis: 'actions/getDashboardEpis',
    }),
    aptoDisponibleCurrent() {
      if (this.dashboardEpis.apto_disponible) {
        return this.dashboardEpis.apto_disponible.current
      }
      return 0
    },
    aptoUsoCurrent() {
      if (this.dashboardEpis.apto_uso) {
        return this.dashboardEpis.apto_uso.current
      }
      return 0
    },
    noAptoCurrent() {
      if (this.dashboardEpis.no_apto) {
        return this.dashboardEpis.no_apto.current
      }
      return 0
    },
    incidenciaCurrent() {
      if (this.dashboardEpis.incidencia) {
        return this.dashboardEpis.incidencia.current
      }
      return 0
    },
    aptoDisponible() {
      if (this.dashboardEpis.apto_disponible) {
        return [
          {
            name: this.$t('Meses'),
            data: this.dashboardEpis.apto_disponible.months,
          },
        ]
      }
      return []
    },
    aptoUso() {
      if (this.dashboardEpis.apto_uso) {
        return [
          {
            name: this.$t('Meses'),
            data: this.dashboardEpis.apto_uso.months,
          },
        ]
      }
      return []
    },
    noApto() {
      if (this.dashboardEpis.no_apto) {
        return [
          {
            name: this.$t('Meses'),
            data: this.dashboardEpis.no_apto.months,
          },
        ]
      }
      return []
    },
    incidencia() {
      if (this.dashboardEpis.incidencia) {
        return [
          {
            name: this.$t('Meses'),
            data: this.dashboardEpis.incidencia.months,
          },
        ]
      }
      return []
    },
  },
  watch: {
    categoryId(){
      this.chargeData()
    },
    clientProp() {
      this.chargeData()
    },
    searchProp() {
      this.chargeData()
    },
    locationProp() {
      this.chargeData()
    },
    albaranProp() {
      this.chargeData()
    },
    statusProp() {
      this.chargeData()
    },
    vida_utilProp() {
      this.chargeData()
    },
    prox_revisionProp() {
      this.chargeData()
    },
    userProp() {
      this.chargeData()
    },
    dateIniProp() {
      this.chargeData()
    },
    dateFinProp() {
      this.chargeData()
    },
  },
  methods: {
    ...mapActions({
      getDashboardEpis: 'actions/getDashboardEpis',
    }),
    chargeData() {
      const clienteCurrent = this.currentClient ? this.currentClient.id : ''
      const client = this.clientprop && this.clientprop !== '0' ? this.clientprop : clienteCurrent

      const category = (this.categoryId) ? this.categoryId : ''
      const search = (this.searchProp) ? this.searchProp : ''
      const statusSelected = []
      if (this.statusProp && this.statusProp.length > 0) {
        this.statusProp.forEach(element => {
          statusSelected.push(element.id)
        })
      }
      const vida_util = (this.vida_utilProp) ? this.vida_utilProp : ''
      const prox_revision = (this.prox_revisionProp) ? this.prox_revisionProp : ''
      const user = (this.userProp) ? this.userProp : ''
      const location = (this.locationProp) ? this.locationProp : ''
      const albaran = (this.albaranProp) ? this.albaranProp : ''

      this.getDashboardEpis({
        client,
        category,
        search,
        status: statusSelected,
        vida_util,
        prox_revision,
        user,
        date_ini: this.dateIniProp,
        date_fin: this.dateFinProp,
        location,
        num_albaran: albaran,
      })
    },
  },
  async created() {
    await this.chargeData()
  },
}
</script>
<style lang="scss" >
</style>
