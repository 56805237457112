<template>
  <div>
    <div class="mb-1">
      <h3 v-if="clientName">{{ $t("Bienvenido") }}, {{ clientName }}</h3>
    </div>
    <b-row v-if="role !== 'prl_cliente'">
      <b-col sm="8">
        <b-card class="height-card" no-body>
          <b-card-header>
            <b-card-title>{{ $t("ResumenTareas") }}</b-card-title>
            <b-card-text class="font-small-2 mr-25 mb-0">
              Updated 1 month ago
            </b-card-text>
          </b-card-header>
          <b-card-body class="d-flex align-items-center">
            <b-row class="w-100">
              <b-col sm="4">
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="40" variant="danger">
                      <feather-icon size="22" icon="ClockIcon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{ this.dataToDo }}
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      {{ $t("TareasPendientes") }}
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col sm="4">
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="40" variant="warning">
                      <feather-icon size="22" icon="TrendingUpIcon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{ this.dataInProgress }}
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      {{ $t("TareasProceso") }}
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col sm="4">
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="40" variant="success">
                      <feather-icon size="22" icon="CheckSquareIcon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{ this.dataComplete }}
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      {{ $t("TareasCompletadas") }}
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col sm="4">
        <b-card class="height-card earnings-card">
          <b-row>
            <b-col cols="12">
              <b-card-title class="mb-1">
                {{ $t("TotalUsuariosActivos") }}
              </b-card-title>
            </b-col>
            <b-col cols="6" class="d-flex align-items-center">
              <div>
                <div class="mb-0">
                  <h2
                    class="text-success d-inline-block font-weight-bolder mr-1"
                  >
                    {{ this.dataUsersActive }}
                  </h2>
                  <small class="text-success d-inline-block">{{
                    $t("UsuariosActivos")
                  }}</small>
                </div>
                <b-card-text class="text-muted font-small-2">
                  <span>de {{ this.dataUsersTotals }}</span>
                </b-card-text>
              </div>
            </b-col>
            <b-col cols="6">
              <vue-apex-charts
                height="120"
                :options="earningsChart"
                :series="seriesUsers"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <div v-if="currentClient">
          <ListAssets v-if="typesAvailable['assets']" />
          <div v-else >
            <ListEpis v-if="typesAvailable['epis']" />
            <div v-else >
              <ListInstallations v-if="typesAvailable['installations']" />
            </div>
          </div>
        </div>
        <ListProducts v-else />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BAvatar,
  BCard,
  BCardHeader,
  BCardBody,
  BCardText,
  BCardTitle,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BMediaBody,
} from "bootstrap-vue";
import { TimestampToFlatPickr } from "@/libs/helpers";
import { mapActions, mapGetters } from "vuex";
import VueApexCharts from "vue-apexcharts";
import { config } from "@/shared/app.config";
import StadisticsAssets from "@/views/assets/Stadistics";
import ListAssets from "@/views/assets/ListTable";
import ListEpis from "@/views/epis/ListTable";
import ListInstallations from "@/views/installations/ListTable";
import ListProducts from "@/views/products/ListTable";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    VueApexCharts,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BCardText,
    StadisticsAssets,
    ListAssets,
    ListEpis,
    ListInstallations,
    ListProducts,
  },
  computed: {
    ...mapGetters({
      currentClient: "clients/getCurrentClient",
      dashboard: "actions/getDashboardData",
      role: 'auth/getRole',
    }),
    typesAvailable() {
      const types = JSON.parse(localStorage.getItem('typesAvailables'))
      return types
    },
    dataToDo() {
      if (this.dashboard.tasks) {
        return this.dashboard.tasks.to_do;
      }
      return "";
    },
    dataInProgress() {
      if (this.dashboard.tasks) {
        return this.dashboard.tasks.in_progress;
      }
      return "";
    },
    dataComplete() {
      if (this.dashboard.tasks) {
        return this.dashboard.tasks.complete;
      }
      return "";
    },
    dataUsersActive() {
      if (this.dashboard.users) {
        return this.dashboard.users.active;
      }
      return 0;
    },
    dataUsersTotals() {
      if (this.dashboard.users) {
        return this.dashboard.users.totals;
      }
      return 0;
    },
    clientName() {
      if (this.currentClient) {
        return this.currentClient.name;
      }
      return "";
    },
    porcentageUsers() {
      if (this.dashboard.users) {
        const porcentage = (this.dataUsersActive * 100) / this.dataUsersTotals;
        return porcentage.toFixed(0);
      }
      return "";
    },
    seriesUsers() {
      return [
        this.dataUsersActive,
        this.dataUsersTotals - this.dataUsersActive,
      ];
    },
    earningsChart() {
      const auxProcentageUser = this.porcentageUsers;
      return {
        chart: {
          type: "donut",
          toolbar: {
            show: false,
          },
        },
        tooltip: {
          enabled: false,
        },
        dataLabels: {
          enabled: false,
        },
        hover: { mode: null },
        legend: { show: false },
        labels: [this.$t("Activados"), this.$t("Desactivados")],
        stroke: { width: 0 },
        colors: ["#c7f4db", "#ffffff"],
        grid: {
          padding: {
            right: -20,
            bottom: -8,
            left: -20,
          },
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  offsetY: 15,
                },
                value: {
                  offsetY: -15,
                  formatter(val) {
                    // eslint-disable-next-line radix
                    return `${parseInt(val)}`;
                  },
                },
                total: {
                  show: true,
                  offsetY: 15,
                  label: this.$t("Activados"),
                  formatter() {
                    return auxProcentageUser + "%";
                  },
                },
              },
            },
          },
        },
        responsive: [
          {
            breakpoint: 1325,
            options: {
              chart: {
                height: 100,
              },
            },
          },
          {
            breakpoint: 1200,
            options: {
              chart: {
                height: 120,
              },
            },
          },
          {
            breakpoint: 1045,
            options: {
              chart: {
                height: 100,
              },
            },
          },
          {
            breakpoint: 992,
            options: {
              chart: {
                height: 120,
              },
            },
          },
        ],
      };
    },
  },
  methods: {
    ...mapActions({
      list: "assets/getListAssets",
      getDashboard: "actions/getDashboardData",
      checkTypeAvailable: "clients/checkTypeAvailable",
    }),
  },
  async created() {
    const client = this.currentClient ? this.currentClient.id : "";
    await this.getDashboard({
      client,
    });
    this.isClientLoaded = true;
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
